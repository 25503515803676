/* tslint:disable */
/* eslint-disable */
/**
 * mjosenskogService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ContractDto } from '../model';
// @ts-ignore
import { ContractModel } from '../model';
// @ts-ignore
import { DeliveredModel } from '../model';
// @ts-ignore
import { DocumentModel } from '../model';
// @ts-ignore
import { DocumentObject } from '../model';
// @ts-ignore
import { MeasuredModel } from '../model';
// @ts-ignore
import { OptionModel } from '../model';
// @ts-ignore
import { RegistrationConfirmationModel } from '../model';
// @ts-ignore
import { ResultForestryModel } from '../model';
// @ts-ignore
import { SettlementModel } from '../model';
// @ts-ignore
import { ShippedToRoadMessageModel } from '../model';
// @ts-ignore
import { TimberLotModel } from '../model';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add shipped to road message
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {ShippedToRoadMessageModel} [shippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateShippedToRoadMessage: async (accountId: string, contractId: string, shippedToRoadMessageModel?: ShippedToRoadMessageModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractCreateShippedToRoadMessage', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractCreateShippedToRoadMessage', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/shippedToRoad`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippedToRoadMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContract: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetContract', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContract', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract documents based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractDocuments: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetContractDocuments', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContractDocuments', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract map images based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractMapImages: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetContractMapImages', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContractMapImages', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/images`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract from CRM based on contract ID.  - signed-pdf from verified
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractSignedPdf: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetContractSignedPdf', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContractSignedPdf', 'contractId', contractId)
            const localVarPath = `/api/v1/account/{accountId}/contract/{contractId}/get-signed-pdf`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of contracts that is ready for signing environment report
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractsReadySigningEnvironmentReports: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetContractsReadySigningEnvironmentReports', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/ready-signing-environment-report`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivered based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetDelivered: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetDelivered', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetDelivered', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/delivered`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetMeasured: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetMeasured', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetMeasured', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/measured`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetMeasuredByNotes: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetMeasuredByNotes', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetMeasuredByNotes', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/measuredByNotes`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get confirmation for contracts with type 3 based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetRegistrationConfirmation: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetRegistrationConfirmation', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetRegistrationConfirmation', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/confirmation`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get result forestry based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetResultForestry: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetResultForestry', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetResultForestry', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/resultForestry`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settlement based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetSettlement: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetSettlement', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetSettlement', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/settlement`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all status codes used in contracts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetStatusCodes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contracts/statusCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all timber contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetTimberContractsByAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetTimberContractsByAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/timber`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all contract types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contracts/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unmeasured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetUnmeasured: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetUnmeasured', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetUnmeasured', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/unmeasured`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all unsigned contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetUnsignedContractsByAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractGetUnsignedContractsByAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/unsigned`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get financial report when work is done
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractReportWorkDone: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractReportWorkDone', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractReportWorkDone', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/reportWorkDone`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign contract
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSignContract: async (accountId: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractSignContract', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractSignContract', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/signContract`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file based on account ID and contract ID
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUploadContractDocument: async (accountId: string, contractId: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('contractUploadContractDocument', 'accountId', accountId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractUploadContractDocument', 'contractId', contractId)
            const localVarPath = `/api/v1/accounts/{accountId}/contracts/{contractId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add shipped to road message
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {ShippedToRoadMessageModel} [shippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCreateShippedToRoadMessage(accountId: string, contractId: string, shippedToRoadMessageModel?: ShippedToRoadMessageModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimberLotModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCreateShippedToRoadMessage(accountId, contractId, shippedToRoadMessageModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractCreateShippedToRoadMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContract(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContract(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract documents based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractDocuments(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractDocuments(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractDocuments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract map images based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractMapImages(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractMapImages(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractMapImages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract from CRM based on contract ID.  - signed-pdf from verified
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractSignedPdf(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractSignedPdf(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractSignedPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of contracts that is ready for signing environment report
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractsReadySigningEnvironmentReports(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractsReadySigningEnvironmentReports(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractsReadySigningEnvironmentReports']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get delivered based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetDelivered(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveredModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetDelivered(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetDelivered']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetMeasured(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasuredModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetMeasured(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetMeasured']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetMeasuredByNotes(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasuredModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetMeasuredByNotes(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetMeasuredByNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get confirmation for contracts with type 3 based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetRegistrationConfirmation(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationConfirmationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetRegistrationConfirmation(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetRegistrationConfirmation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get result forestry based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetResultForestry(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultForestryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetResultForestry(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetResultForestry']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get settlement based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetSettlement(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetSettlement(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetSettlement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all status codes used in contracts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetStatusCodes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetStatusCodes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetStatusCodes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all timber contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetTimberContractsByAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetTimberContractsByAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetTimberContractsByAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all contract types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get unmeasured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetUnmeasured(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveredModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetUnmeasured(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetUnmeasured']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all unsigned contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetUnsignedContractsByAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetUnsignedContractsByAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetUnsignedContractsByAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get financial report when work is done
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractReportWorkDone(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultForestryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractReportWorkDone(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractReportWorkDone']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sign contract
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSignContract(accountId: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractSignContract(accountId, contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractSignContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload file based on account ID and contract ID
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUploadContractDocument(accountId: string, contractId: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUploadContractDocument(accountId, contractId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUploadContractDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Add shipped to road message
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {ShippedToRoadMessageModel} [shippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateShippedToRoadMessage(accountId: string, contractId: string, shippedToRoadMessageModel?: ShippedToRoadMessageModel, options?: any): AxiosPromise<TimberLotModel> {
            return localVarFp.contractCreateShippedToRoadMessage(accountId, contractId, shippedToRoadMessageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContract(accountId: string, contractId: string, options?: any): AxiosPromise<ContractModel> {
            return localVarFp.contractGetContract(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract documents based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractDocuments(accountId: string, contractId: string, options?: any): AxiosPromise<Array<DocumentObject>> {
            return localVarFp.contractGetContractDocuments(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract map images based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractMapImages(accountId: string, contractId: string, options?: any): AxiosPromise<Array<DocumentObject>> {
            return localVarFp.contractGetContractMapImages(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract from CRM based on contract ID.  - signed-pdf from verified
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractSignedPdf(accountId: string, contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.contractGetContractSignedPdf(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of contracts that is ready for signing environment report
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractsReadySigningEnvironmentReports(accountId: string, options?: any): AxiosPromise<Array<ContractDto>> {
            return localVarFp.contractGetContractsReadySigningEnvironmentReports(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get delivered based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetDelivered(accountId: string, contractId: string, options?: any): AxiosPromise<DeliveredModel> {
            return localVarFp.contractGetDelivered(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetMeasured(accountId: string, contractId: string, options?: any): AxiosPromise<MeasuredModel> {
            return localVarFp.contractGetMeasured(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get measured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetMeasuredByNotes(accountId: string, contractId: string, options?: any): AxiosPromise<MeasuredModel> {
            return localVarFp.contractGetMeasuredByNotes(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get confirmation for contracts with type 3 based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetRegistrationConfirmation(accountId: string, contractId: string, options?: any): AxiosPromise<RegistrationConfirmationModel> {
            return localVarFp.contractGetRegistrationConfirmation(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get result forestry based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetResultForestry(accountId: string, contractId: string, options?: any): AxiosPromise<ResultForestryModel> {
            return localVarFp.contractGetResultForestry(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settlement based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetSettlement(accountId: string, contractId: string, options?: any): AxiosPromise<SettlementModel> {
            return localVarFp.contractGetSettlement(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all status codes used in contracts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetStatusCodes(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.contractGetStatusCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all timber contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetTimberContractsByAccount(accountId: string, options?: any): AxiosPromise<Array<ContractDto>> {
            return localVarFp.contractGetTimberContractsByAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all contract types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetTypes(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.contractGetTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unmeasured based on account ID and contract ID.
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetUnmeasured(accountId: string, contractId: string, options?: any): AxiosPromise<DeliveredModel> {
            return localVarFp.contractGetUnmeasured(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all unsigned contracts based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetUnsignedContractsByAccount(accountId: string, options?: any): AxiosPromise<Array<ContractDto>> {
            return localVarFp.contractGetUnsignedContractsByAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get financial report when work is done
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractReportWorkDone(accountId: string, contractId: string, options?: any): AxiosPromise<ResultForestryModel> {
            return localVarFp.contractReportWorkDone(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign contract
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSignContract(accountId: string, contractId: string, options?: any): AxiosPromise<ContractModel> {
            return localVarFp.contractSignContract(accountId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file based on account ID and contract ID
         * @param {string} accountId 
         * @param {string} contractId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUploadContractDocument(accountId: string, contractId: string, file?: File, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.contractUploadContractDocument(accountId, contractId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary Add shipped to road message
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {ShippedToRoadMessageModel} [shippedToRoadMessageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCreateShippedToRoadMessage(accountId: string, contractId: string, shippedToRoadMessageModel?: ShippedToRoadMessageModel, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCreateShippedToRoadMessage(accountId, contractId, shippedToRoadMessageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContract(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContract(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract documents based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractDocuments(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractDocuments(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract map images based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractMapImages(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractMapImages(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract from CRM based on contract ID.  - signed-pdf from verified
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractSignedPdf(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractSignedPdf(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of contracts that is ready for signing environment report
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractsReadySigningEnvironmentReports(accountId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractsReadySigningEnvironmentReports(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get delivered based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetDelivered(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetDelivered(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get measured based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetMeasured(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetMeasured(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get measured based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetMeasuredByNotes(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetMeasuredByNotes(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get confirmation for contracts with type 3 based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetRegistrationConfirmation(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetRegistrationConfirmation(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get result forestry based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetResultForestry(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetResultForestry(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settlement based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetSettlement(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetSettlement(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all status codes used in contracts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetStatusCodes(options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetStatusCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all timber contracts based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetTimberContractsByAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetTimberContractsByAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all contract types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetTypes(options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unmeasured based on account ID and contract ID.
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetUnmeasured(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetUnmeasured(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all unsigned contracts based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetUnsignedContractsByAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetUnsignedContractsByAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get financial report when work is done
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractReportWorkDone(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractReportWorkDone(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign contract
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractSignContract(accountId: string, contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractSignContract(accountId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file based on account ID and contract ID
     * @param {string} accountId 
     * @param {string} contractId 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUploadContractDocument(accountId: string, contractId: string, file?: File, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUploadContractDocument(accountId, contractId, file, options).then((request) => request(this.axios, this.basePath));
    }
}

